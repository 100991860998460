export default {
  async update (Vue, id, formData) {
    await Vue.$offline.lparteTrabajo.updateSync({
      idlparte_trabajo: id,
      facturar: formData.facturar,
      idtmotivo_nofacturable: formData.idtmotivo_nofacturable,
      descripcion: formData.descripcion,
      unidades: formData.unidades,
      nserie: formData.nserie,
      nelemento: formData.nelemento,
      ubicacion: formData.ubicacion,
      netiqueta: formData.netiqueta,
      numzona: formData.numzona,
      evento: formData.evento,
      funcion: formData.funcion,
      particion: formData.particion,
      // fultrevision (no debe ser editable)
      // fprox_revision (no existe aún en offline, es un campo calculado en el servidor)
      fult_retimbrado: formData.fult_retimbrado,
      // fprox_retimbrado (no existe aún en offline, es un campo calculado en el servidor)
      // fcaducidad
      // ffin_garantia
      idfabricante: formData.idfabricante,
      ffabricacion: formData.ffabricacion,
      idagente_extintor: formData.idagente_extintor,
      idagente_propelente: formData.idagente_propelente,
      volumen: formData.volumen,
      peso_total: formData.peso_total,
      peso_agente_extintor: formData.peso_agente_extintor,
      eficacia: formData.eficacia,
      presion: formData.presion,
      observaciones: formData.observaciones,
      idalmacen: formData.idalmacen,
    })
  },
}
